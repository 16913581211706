<template>
    <partial-map-with-sidebar :map-mandatory="false">
        <template v-slot:sidebar>
            <slot/>
        </template>

        <template v-slot:sidebar-footer>
            <slot name="sidebar-footer"/>
        </template>

        <template v-slot:map>
            <map-dashboard/>
        </template>

        <template slot:sidebar-header>
            <slot name="header"/>
        </template>

        <template slot:footer>
            <div>
                <language-switcher />
            </div>
        </template>
    </partial-map-with-sidebar>
</template>